import styled from '@emotion/styled'
import { BigTitle } from 'app/components/BigTitle'
import { BlockCTAHome } from 'app/components/BlockCTAHome'
import { DealsPreview } from 'app/components/DealsPreview'
import { GalleryRooms } from 'app/components/GalleryRooms'
import { GalleryTriple } from 'app/components/GalleryTriple'
import { Hero } from 'app/components/Hero'
import { InstagramSlider } from 'app/components/InstagramSlider'
import { IntroHome } from 'app/components/IntroHome'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ZigZags } from 'app/components/ZigZags'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any
  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          syncroProps={context.syncroProps ? context.syncroProps : null}
          flashNewsProps={
            context.flashNewsProps ? context.flashNewsProps : null
          }
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introHomeProps ? (
        <IntroHome {...context.introHomeProps} />
      ) : null}
      {context.galleryRoomsProps ? (
        <GalleryRooms {...context.galleryRoomsProps} />
      ) : null}
      {context.galleryTripleProps ? (
        <GalleryTriple {...context.galleryTripleProps} />
      ) : null}
      {context.bigTitleProps ? <BigTitle {...context.bigTitleProps} /> : null}
      {context.zigZagsProps ? <ZigZags {...context.zigZagsProps} /> : null}
      {context.blockCTAHomeProps ? (
        <BlockCTAHome {...context.blockCTAHomeProps} />
      ) : null}
      {context.dealsPreviewProps ? (
        <DealsPreview {...context.dealsPreviewProps} />
      ) : null}
      {context.instagramProps ? (
        <InstagramSlider {...context.instagramProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
