import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ArrowsRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import React, { memo, useEffect, useState } from 'react'

const SUPPORTED_CURSORS = [false, 'pointer', 'right', 'left']

export interface Props {
  container?: any
  gallery?: any
  hasOffset?: boolean
  isModal?: boolean
  onClick?: any
  thumbs?: any
  variant?: Variant
}

export const Cursor = memo(function Cursor({
  container,
  hasOffset,
  isModal,
  gallery,
  thumbs,
  variant = 'default',
}: Props) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [cursor, setCursor] = useState(false)

  const getOffset = (element: any) => {
    const rect = element.getBoundingClientRect()
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    }
  }

  const onMouseMove = (e: any) => {
    const { pageX: x, pageY: y, clientY: yy } = e

    if (container.current) {
      if (hasOffset) {
        setMousePosition({
          x: x - getOffset(container.current).left - 30,
          y: isModal
            ? yy
            : y -
              container.current?.offsetTop -
              getOffset(container.current).top -
              30,
        })
      } else {
        setMousePosition({
          x: x - 30,
          y: isModal ? yy : y - container.current?.offsetTop - 30,
        })
      }
    }
  }

  const onCursor = (cursorType: any) => {
    cursorType = (SUPPORTED_CURSORS.includes(cursorType) && cursorType) || false

    setCursor(cursorType)
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
    }
  }, [container])

  return (
    <>
      <Icon
        className={`${!!cursor ? 'active' : ''}${cursor ? ` ${cursor}` : ''}`}
        dial={5}
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
        }}
        variant={variant}
      >
        <ArrowsRight />
      </Icon>

      {['right', 'left'].map((side, index) => (
        <Side
          className={side}
          key={index}
          onClick={(e: any) =>
            e.stopPropagation() || side === 'left'
              ? [gallery?.current?.prev(), thumbs?.current?.prev()]
              : [gallery?.current?.next(), thumbs?.current?.next()]
          }
          onMouseEnter={() => onCursor(side)}
          onMouseLeave={onCursor}
        />
      ))}
    </>
  )
})

const Icon = styled(FlexBox)<ContainerProps>`
  position: absolute;
  width: 5.55vw;
  height: 5.55vw;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
  transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out;
  z-index: 3;

  @media (max-width: 1439px) {
    width: 80px;
    height: 80px;
  }

  &.active {
    opacity: 1;
    transform: scale(1);
  }

  &.left {
    svg {
      transform: scaleX(-1);
    }
  }

  svg {
    width: auto;
    height: 1.96vw;
    fill: ${({ theme }) => theme.colors.variants.neutralLight1};

    @media (max-width: 1439px) {
      height: 28px;
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'light':
        return css`
          background: ${theme.colors.variants.neutralLight1};

          svg {
            fill: ${theme.colors.variants.neutralDark2};
          }
        `
    }
  }}

  @media (max-width: 991px) {
    display: none;
  }
`

const Side = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 3;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }
  @media (max-width: 991px) {
    display: none;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'light'
