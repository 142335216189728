import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

import { Path } from './Path'

export interface Props {
  description?: string
  image?: ImageProps
  pretitle?: string
  subtitle?: string
  title?: string
}

export const IntroHome = memo(function IntroHome({
  description,
  image,
  pretitle,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <StyledDots width={5} height={5} variant="light" />
        <Left>
          {image?.src ? (
            <FadeIn>
              <Path URL={image.src} />
            </FadeIn>
          ) : null}
        </Left>
        <Right>
          {pretitle ? (
            <FadeInUp>
              <Line />
              <Pretitle>{pretitle}</Pretitle>
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {subtitle ? (
            <FadeInUp>
              <Dot />
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 20vh 10vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-bottom: -1px;

  @media (max-width: 991px) {
    padding: 70px 30px;
    padding-top: 90px;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`

const Left = styled.div`
  width: 50%;
  padding-right: 10vw;

  @media (max-width: 991px) {
    display: none;
  }
`

const Right = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledDots = styled(Dots)`
  position: absolute;
  top: 0;
  right: 0;
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.13;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`

const Description = styled.div`
  position: relative;
  margin-top: 3.33vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1.04vw;
  line-height: 1.73;

  @media (max-width: 1439px) {
    font-size: 15px;
    line-height: 26px;
    margin-top: 20px;
  }
`

const Dot = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 1.66vw;
  width: 1.66vw;
  margin-top: 4.79vw;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 991px) {
    width: 10px;
    height: 10px;
  }
`

const Subtitle = styled.h3`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: 4.79vw;
  margin-left: 1.38vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 2.22vw;
  line-height: 1.31;

  @media (max-width: 991px) {
    font-size: 22px;
    margin-top: 20px;
  }
`
