import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  URL: string
}

export const Path = memo(function Path({ URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="444.92"
      height="514"
      viewBox="0 0 444.92 514"
    >
      <defs>
        <pattern
          id="pattern"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
          viewBox="0 0 1400 1400"
        >
          <image width="1400" height="1400" href={URL} />
        </pattern>
      </defs>
      <g id="intro-svg" transform="translate(-143.235 -1100)">
        <path
          d="M0,0H0V514a258.9,258.9,0,0,0,51.794-5.221A255.7,255.7,0,0,0,100.036,493.8a257.033,257.033,0,0,0,43.655-23.7,258.853,258.853,0,0,0,38.035-31.382,258.843,258.843,0,0,0,31.382-38.035,257.015,257.015,0,0,0,23.7-43.655,255.7,255.7,0,0,0,14.975-48.241A258.9,258.9,0,0,0,257,257a258.9,258.9,0,0,0-5.221-51.795A255.7,255.7,0,0,0,236.8,156.964a257.023,257.023,0,0,0-23.7-43.655,258.847,258.847,0,0,0-31.382-38.035,258.857,258.857,0,0,0-38.035-31.382,257.033,257.033,0,0,0-43.655-23.7A255.71,255.71,0,0,0,51.795,5.221,258.9,258.9,0,0,0,0,0Z"
          transform="translate(400.235 1614) rotate(180)"
          fill="#4e738a"
        />
        <path
          d="M666.433,565.39l-81.541-81.781a45,45,0,0,1-13.707-33.083c0-13.124,4.457-24.453,13.707-33.383,8.943-9.236,19.962-13.72,33.08-13.72s23.841,4.76,33.1,13.72l81.959,82.2Q758.8,456.83,758.8,403.735c0-51.359-18.219-95.55-54.648-131.683-36.429-36.712-80.308-54.928-131.959-54.928-51.366,0-95.534,18.216-131.963,54.928-36.429,36.133-54.645,80.324-54.645,131.683,0,51.635,18.216,95.531,54.645,132.249,36.429,36.139,80.6,54.349,131.963,54.349Q624.608,590.332,666.433,565.39Z"
          transform="translate(-171.147 953.33)"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
          fill="url(#pattern)"
        />
      </g>
    </Container>
  )
})

const Container = styled.svg`
  width: 100%;
  height: auto;

  .intro-path {
    fill: ${({ theme }) => theme.colors.variants.primaryLight};
  }
`
